// De standaard kleuren https://www.figma.com/file/LfZ3lShg99bl9lCe9t5w54/Harmony?node-id=0%3A1
// uit CumLaude Product Language
$color-cl-background-1: #f3f3f3;
$color-cl-background-2: #e6e6e5;
$color-cl-background-3: #ffffff;
$color-cl-background-4: #d0dae4;

//deze niet
$background-5: #f7f9fa;
$background-6: #fff;

// uit CumLaude Product Language
$color-cl-typography-1: #1d1d1b;
$color-cl-typography-2: #3b4d68;
$color-cl-typography-3: #607798;
$color-cl-typography-4: #ffffff;
$color-cl-typography-5: #b9c0c6;

$color-cl-primary-1: #3669ab;
$color-cl-primary-2: #1b395a;
$color-cl-primary-3: #85c2c4;
$color-cl-secondary-1: #274f84;

$color-cl-error-1: #d11212;
$color-cl-error-2: #ffddd8;
$color-cl-error-3: #f9e6e4;

$color-cl-warning-1: #ff7a00;
$color-cl-warning-2: #fff1e7;

$color-cl-info-1: #156af7;
$color-cl-info-2: #eaf0fb;

$color-cl-positive-1: #2e8130;
$color-cl-positive-2: #e8f0e8;

$color-cl-chart-paars: #d6a1ff;
$color-cl-chart-roze: #f88ffa;

$primary-1: #296bd7;
$primary-2: #005eb8;
$primary-3: #004f9b;
$primary-4: #e5edfa;

$secondary-1: #e6ab4a;
$secondary-2: #c38831;
$secondary-3: #fbf2e4;

$accent-positive-1: #3f8541;
$accent-positive-2: #1a6d1c;
$accent-positive-3: #e8f0e8;

$accent-negative-1: #d32f0d;
$accent-negative-2: #b62507;
$accent-negative-3: #f8e0db;

$accent-info-1: #156af6;
$accent-info-2: #eaf0fb;

$accent-warning-1: #da6710;
$accent-warning-2: #b64603;
$accent-warning-3: #f9e8db;

$accent-alt-1: #793ff5;
$accent-alt-2: #5012d2;
$accent-alt-3: #ebe2fe;

//size:
$icon-size: 1em;

$padding-lesitem-mobiel: 10px;
$padding-lesitem-tablet: 25px;
$padding-lesitem-desktop: 45px;

$sidebar-width: 14.25rem;
$filterpanel-width: 17.5rem;
$header-height: 4.25rem;
$tab-height: 2.75rem;

// Borders:
$border-body: 1px solid $color-cl-background-3;
$border-body-dashed: 1px dashed $color-cl-background-3;

$border-light: 1px solid $color-cl-background-2;
$border-contrast: 1px solid $color-cl-typography-5;
$border-contrast-dashed: 1px dashed $color-cl-background-2;

$border-primary: 1px solid $color-cl-primary-1;
$border-positive: 1px solid $accent-positive-1;
$border-warning: 1px solid $accent-warning-1;
$border-error: 1px solid $accent-negative-1;
$border-notification: 2px solid $background-6;
$border-divider-line: 1px solid $color-cl-background-2;

$br-normal: 3px;
$br-top: 3px 3px 0 0;
$br-bottom: 0 0 3px 3px;
$br-left: 3px 0 0 3px;
$br-right: 0 3px 3px 0;

$br-large: 5px;
$br-top-large: 5px 5px 0 0;
$br-bottom-large: 0 0 5px 5px;
$br-left-large: 5px 0 0 5px;
$br-right-large: 0 5px 5px 0;
$br-label: 50px;

// Icon groottes:
$icon-smallest: 12px;
$icon-small: 16px;
$icon-medium: 20px;
$icon-large: 24px;

$icon-header: 28px;
$icon-navigation: 36px;
$icon-application: 44px;

// Box-shadows:
$shadow-content-container: 0 0 4px rgba($color-cl-typography-1, 0.15);
$shadow-sidebar-left: -4px 0 8px rgba($color-cl-typography-1, 0.25);
$shadow-sidebar-right: 3px 0 8px rgba($color-cl-typography-1, 0.25);
$shadow-page-container: 0 1px 1px rgba($color-cl-typography-1, 0.25);
$shadow-popout-mobile: 0 -4px 16px rgba($color-cl-typography-1, 0.25);
$shadow-popout-desktop: 0 4px 14px rgba($color-cl-typography-1, 0.25);
$shadow-floating: 0 4px 16px rgba($color-cl-typography-1, 0.25);
$shadow-button-soft: 0 2px 8px rgba($color-cl-typography-1, 0.15);
$shadow-button-hard: 0 1px 3px rgba($color-cl-typography-1, 0.2);
$shadow-bar: 0 -10px 20px rgba($color-cl-typography-1, 0.2);
$shadow-card: 0 2px 8px rgba($color-cl-typography-1, 0.25);
$shadow-form-active: 0 0 8px rgba($color-cl-primary-1, 0.25);
$shadow-button-hover: 0 2px 8px rgba($color-cl-primary-2, 0.12);
$shadow-container-card: 0 2px 8px rgba($color-cl-primary-2, 0.25);

// CumLaude-kleuren:
$cumlaude-wit: white;
$cumlaude-zwart: black;
$cumlaude-rood: #f15b49;
$cumlaude-oranje: #ffa959;
$cumlaude-geel: #ffda59;
$cumlaude-groen: #65c6c4;
$cumlaude-donkerblauw: #043d5f;
$cumlaude-blauw: #1870b5;
$cumlaude-grijs: #dedfdd;
$cumlaude-lichtgrijs: #edeeed;
$cumlaude-donkergrijs: #c4c4c4;

$label-magister: #0ea58e;
$label-somtoday: #005eb5;
$label-bestuur: $color-cl-background-3;
$label-vso: $color-cl-primary-2;

$bar-default: $cumlaude-blauw;

$bar-opstroom: #00940f;
$bar-opstroom-doublure: #ffe280;
$bar-doorstroom: #4ed33a;
$bar-geslaagd: $bar-doorstroom;
$bar-afstroom: #c18edd;
$bar-afstroom-doublure: #8f69d8;
$bar-doublure: #ffa04a;
$bar-afgewezen: $bar-doublure;
$bar-onbekend: #e4e4e4;
$bar-onbekend-alt: $color-cl-typography-3;
$bar-geen-doorstroom: $cumlaude-wit;
$bar-cohortrendement-afgewezen: #fa5a5a;

$line-opstroom: #bdf263;
$line-doorstroom: #73ce00;
$line-geslaagd: $line-doorstroom;
$line-afstroom: #d6a1ff;
$line-doublure: #ffa959;
$line-afgewezen: $line-doublure;
$line-onbekend: #e4e4e4;

$bar-geslaagd-geslaagd: #bdf162;
$bar-geslaagd-afgewezen: #cd3025;
$bar-geslaagd-tijdens-examen-teruggetrokken: #feb56b;
$bar-geslaagd-voortijdig-teruggetrokken: #f2df35;
$bar-geslaagd-overig: $cumlaude-grijs;

$bar-kenmerk: $color-cl-primary-1;
$bar-geenkenmerk: $cumlaude-grijs;

$bar-overgang-met: $cumlaude-geel;
$bar-overgang-zonder: $cumlaude-grijs;

$bar-geoorloofd: #ffda59;
$bar-ongeoorloofd: #d6a1ff;

$bar-huiswerk-niet-in-orde: #4ca8ee;
$bar-materiaal-niet-in-orde: #c18edd;
$bar-te-laat: #9be7ff;
$bar-verwijderd: #ef5050;
$bar-mobiel: $color-cl-chart-roze;
$bar-lesregistratie-overig: $cumlaude-donkergrijs;

$bar-verschil--25: #ac1e41;
$bar-verschil--20: #d01145;
$bar-verschil--15: #e94545;
$bar-verschil--10: #ff7659;
$bar-verschil--5: #dddddd;
$bar-verschil-0: #e7e7e7;
$bar-verschil-5hv: #b4ff82;
$bar-verschil-5: #9ff277;
$bar-verschil-10: #75e761;
$bar-verschil-15: #44cc4f;
$bar-verschil-20: #15ac45;
$bar-verschil-25: #218f53;

$bar-cijfer-0-3: #b30d04;
$bar-cijfer-4: #d11212;
$bar-cijfer-5: #ea622c;
$bar-cijfer-6: #c6fd68;
$bar-cijfer-7: #67e40b;
$bar-cijfer-8: #5dc867;
$bar-cijfer-9: #0ea58e;
$bar-cijfer-10: #14768b;
$bar-cijfer-onvoldoende: #feb50a;
$bar-cijfer-voldoende: #78ddfe;
$bar-cijfer-onbekend: $cumlaude-lichtgrijs;
$bar-cijfer-advies: #d6a1ff;

$line-cijfer-0-3: #af0b02;
$line-cijfer-4: #e40303;
$line-cijfer-5: #ed5316;
$line-cijfer-6: #73ce00;
$line-cijfer-7: #15bb07;
$line-cijfer-8: #00940f;
$line-cijfer-9: #068869;
$line-cijfer-10: #06655a;
$line-cijfer-onvoldoende: #feb50a;
$line-cijfer-voldoende: #78ddfe;
$line-cijfer-advies: #d6a1ff;

$bar-iqpunt-1: #dc6083;
$bar-iqpunt-2: #f08b80;
$bar-iqpunt-3: #fab185;
$bar-iqpunt-4: #fdd09a;
$bar-iqpunt-5: #aff0b8;
$bar-iqpunt-6: #7be2be;
$bar-iqpunt-7: #35cdcb;
$bar-iqpunt-8: #00add1;

$bar-uitstroompunt-50: #a32d6e;
$bar-uitstroompunt-55: #b73c78;
$bar-uitstroompunt-60: #dc6083;
$bar-uitstroompunt-70: #f08b80;
$bar-uitstroompunt-80: #fab185;
$bar-uitstroompunt-85: #fdd09a;
$bar-uitstroompunt-90: #aff0b8;
$bar-uitstroompunt-100: #7be2be;
$bar-uitstroompunt-105: #35cdcb;
$bar-uitstroompunt-110: #00add1;
$bar-uitstroompunt-115: #0085bd;
$bar-uitstroompunt-120: #006ea5;

$bar-percentiel-tot-25: $bar-cijfer-5;
$bar-percentiel-25-75: #d9d9d9;
$bar-percentiel-va-75: $bar-cijfer-7;

$bar-niveau-00f: #09213b;
$bar-niveau-05f: #043f81;
$bar-niveau-10f: #1738e6;
$bar-niveau-15f: #8b3cf1;
$bar-niveau-20f: #c11afb;
$bar-niveau-25f: #ed60f9;
$bar-niveau-30f: #f691ff;
$bar-niveau-35f: #ffb6e2;
$bar-niveau-40f: #ffccd9;

$cell-onbekend: #aaaaaa;

$hover-help-footer: #eaf0fb;
